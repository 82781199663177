import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { red } from '@mui/material/colors';

import MuiAppBar from './MuiAppBar';
import typography from './typography';

// Create a theme instance.
let theme = createTheme({
  typography,
  components: {
    MuiAppBar,
  },
  palette: {
    mode: 'dark',
    background: {
      default: '#030303',
      paper: '#070809',
    },
    primary: {
      main: `#9EF3B5`,
      // main: '#a71415',
      // main: `#00BFA6`
      // main: `#A3C8F4`
      // main: `#75FF02`
      // main: `#64DD16`
    },
    secondary: {
      main: `#DE1B34`,
      // main: '#f50057',
    },
    error: {
      main: red.A400,
    },
  },
});

theme = responsiveFontSizes(theme, { factor: 1.5 });

export default theme;
